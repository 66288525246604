@font-face {
    font-family: Billabong;
    src: url('../../font/snapshot/Billabong.ttf');
}

:root {
    --font-billabong: Billabong;
}

.ff-billabong {
    font-family: var(--font-billabong);
}

.ff-billabong.bold {

    font-weight: 700;
    letter-spacing: 0.25rem;
}

.showdow-sm {
    box-shadow: 0 0px 6px hsla(210, 10%, 23%, 0.15);
}

.snapchat {
    display: flex;
    z-index: 3;
    height: 44px;
    background: #fff;
    border: 1px solid transparent;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    margin: 0 auto;
}

.snapchat .search-input {
    border: none;
    outline: 0;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    ;
}

.snapchat .search-icon {
    padding-right: 16px;
    padding-left: 16px;
    margin-left: -1px;
    color: #9aa0a6;
    font-size: 1.25rem;
    line-height: 42px;
}

.snapchat .search-btn {
    padding: 0 .75rem;
    border: 1px solid transparent;
    background-color: transparent;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}

.snapchat .search-btn>i {
    font-size: 1.25rem;
    line-height: 42px;
    color: #9aa0a6;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
        position: relative;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

.card-btn-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
}
/* .card-btn-wrapper . */