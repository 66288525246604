@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&family=Poppins&family=Yeseva+One&display=swap');

:root {
  --theme-black: #000000;
  --theme-black-second: #3E3636;
  --theme-red: #D72323;
  --theme-white: #F5EDED;
  --theme-black-rgb: 0, 0, 0;
  --theme-black-second-rgb: 62, 54, 54;
  --theme-red-rgb: 215, 35, 35;
  --theme-white-rgb: 245, 237, 237;
  --playfair: 'Playfair Display', serif;
  --poppins: 'Poppins', sans-serif;
  --yeseva-one: 'Yeseva One', cursive;
  --border-radius-9: 9px;
}

p {
  font-family: var(--poppins);
  font-weight: 300;
}

::selection {
  background-color: #ffce00;
  text-shadow: none;
  color: #fff;
}

/* the background of the bar itself. */
::-webkit-scrollbar {
  width: 10px;
  background: #f5f9fa;

}

/* the directional buttons on the scrollbar. */
::-webkit-scrollbar-button {
  height: 14%;
  width: 10px;
}

/* he empty space “below” the progress bar. */
::-webkit-scrollbar-track {
  padding: 10px 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 6px;
}

/* the top-most layer of the the progress bar not covered by the thumb. */
::-webkit-scrollbar-track-piece {
  /* background: rgba(13, 110, 253, 0.1); */
  color: gold;
}

/* the draggable scrolling element resizes depending on the size of the scrollable element. */
::-webkit-scrollbar-thumb {
  background: rgb(13, 110, 253, 0.2);
}

/* the bottom corner of the scrollable element, where two scrollbar meet. */
/* ::-webkit-scrollbar-corner {
} */

/* the draggable resizing handle that appears above the scrollbar-corner at the bottom corner of some elements. */
/* ::-webkit-resizer {
} */

#headerTopbar .header-icon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

#home {
  margin-top: 4.5rem;
}

#home h1 {
  font-family: var(--playfair);
  font-size: calc(1.525rem + 1.75vmin + 1.75vmax);
}

#home h1>.small {
  font-size: calc(1.1875rem + 0.625vmin + 0.625vmax);
}

.ff-poppins {
  font-family: var(--poppins) !important;
}

.ff-playfair {
  font-family: var(--playfair) !important;
}

.ff-yeseva {
  font-family: var(--yeseva-one) !important;
}

.fs-normal {
  font-size: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

#logo-small {
  fill: rgb(0, 0, 0);
}

/* body{
  /* background-color: var(--theme-black) !important;
  color:var(--theme-white)!important; */
.center-v {
  display: flex;
  align-items: center;
  text-decoration: none;
}



.circle {
  border-radius: 50% !important;
}

.circle,
.square {
  align-items: center;
  background-position: center;
  background-size: cover;
  display: inline-flex;
  justify-content: center;
  overflow: hidden;
  padding: 50%;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.social-icons>a {
  font-size: calc(1.075rem + 0.25vmin + 0.25vmax) !important;
}

.bg-secondary-o {
  background-color: hsla(207, 56%, calc(66% * 1), 1);
}

.form-control.form-clean {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
}

.form-control.form-clean:focus {
  background-color: transparent;
  box-shadow: none;
}

.portfolio-item {
  /* border-width: 1.5rem; */
  /* border-radius: 3rem; */
  border-width: 0.2rem;
  border-radius: 1rem;
  border-style: solid;
  border-color: hsla(210, 10%, 23%, 0.05);
}

.border-dark {
  border-color: hsla(210, 10%, 23%, 0.05, ) !important;
}

.svg-shadow {
  filter: drop-shadow(3px 3px 0.625rem rgba(0, 0, 0, 0.11));
  /* filter: drop-shadow(0rem, 0rem calc(0.25rem + 0rem, 0rem) 0.625rem rgba(0rem, calc(0.15 * 0rem))) !important; */
}

.font-sans-serif {
  font-family: var(--poppins), sans-serif !important;
  font-weight: 400;
}

.skill h3 {
  font-family: var(--poppins) !important;
  font-weight: 600;
  letter-spacing: 0.045em;
}

.rounded-xl {
  border-radius: 0.5rem !important;
}

.w-20 {
  width: 20% !important;
}

.mb-6 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.p-6 {
  padding: 3rem !important;
}

.btn-soft-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.2);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}

.title-badge {
  position: relative;
  margin-right: 3rem;
}

.title-badge::after {
  content: "";
  border-top: 1px solid black;
  display: block;
  position: absolute;
  top: 0;
  right: -3rem;
  width: 2.5rem;
  bottom: 0;
  margin: auto;
  height: 1px;
}

.theme-dark .title-badge::before {
  border-top-color: #fff;
}


.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

@media (min-width: 768px) {
  .py-md-8 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
}

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.social-icons>a {
  color: #33363b;
  opacity: .8;
}

.social-icons>a:hover {
  color: #33363b;

}

.fs-125 {
  font-size: 1.25rem;
}

.tor-slider .tor-slider-items {
  display: flex;
  flex-wrap: nowrap;
  height: inherit;
  width: 100%;
  white-space: nowrap;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  touch-action: manipulation;
  transform: translate3d(0px, 0px, 0px);

  /*
  */
  gap: 5px;
}

.tor-slider .tor-slider-items:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tor-slider-item .portfolio-item {
  width: 100%;
}


.tor-slider {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 1px;
  position: relative;
  touch-action: manipulation;
  transform-style: preserve-3d;
  overflow: hidden;
}

.tor-slider .tor-slider-inner {
  overflow: hidden;
  width: 100%;
  height: inherit;
  position: relative;
}

.circle-lg,
.square-lg {
  padding: 2.375rem;
}

.circle {
  border-radius: 50% !important;
}

.project-card {
  border-radius: var(--border-radius-9);
  position: relative;
  overflow: hidden;
}

.project-card::after {
  content: "";
  pointer-events: none;
  position: absolute;
  z-index: 1;
  inset: 0px;
  opacity: 0.3;

  background: linear-gradient(rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.78) 80%);
}

.project-card>.card-body {
  position: absolute;
  z-index: 1;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.78) 80%);
  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 3%, rgba(0, 0, 0, 0.45) 19%, rgba(0, 0, 0, 0.65) 77%); */
  transform: translateY(10%);
  transition: opacity 300ms ease-in-out 0s, transform 300ms ease-in-out 0s;
  opacity: 0;
  color: #F5EDED;
  height: 100%;
  width: 100%;
}

.project-card:hover .card-body {
  z-index: 999;
  transform: none;
  opacity: 1;
}

.project-card>.card-body .card-title {
  font-weight: 800;
  font-size: 1.667em;
}

.project-card>.card-body .card-text {
  font-size: calc(0.875em + 0.9px);
  color: rgb(213, 213, 213);
}

.project-card>.card-body .pills {
  background: rgb(105, 104, 105);
  box-shadow: 0px 0px 2px rgb(0, 0, 0, 0.5);
  padding: 4px 10px;
  border-radius: 50px;
  text-transform: capitalize;
  font-size: 11px;
  margin-right: 6px;
  color: rgb(255, 255, 255);
  font-weight: 500;
}

.project-card>.card-body .content {
  position: absolute;
  bottom: 20px;
}

/*Canvas */
.canvas-title {
  font-size: calc(1.667em - 6px);
  color: #000000;
  font-weight: 800;
  margin-bottom: 0.5rem;
}

.canvas-text p {
  font-size: 0.875em;
  color: #545454;
}

.canvas-text h4 {
  font-size: 1.124em;
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 0.5rem;

}

/**End Canvas **/


.theme-shadow {
  filter: drop-shadow(6px 6px 4px rgba(0, 0, 0, .3));
}