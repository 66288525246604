#illustration-hero .st1 {
    fill: #a15842
}

#illustration-hero .st2 {
    fill: #30242a
}

#illustration-hero .st3 {
    fill: #ffcfc9;
}

#illustration-hero .st5 {
    fill: none;
    stroke: #a15842;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-miterlimit: 10
}

#illustration-hero .st6 {
    fill: #f5ebdd
}

#illustration-hero .st7 {
    fill: none;
    stroke: #30242a;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-miterlimit: 10
}

#illustration-hero .st8 {
    fill: #a03c8b
}

#illustration-hero .st9 {
    fill: #454988
}

#illustration-hero .st10 {
    fill: #ffb7d5
}

#illustration-hero .st11 {
    fill: #ffbe21
}

#illustration-hero .st12 {
    fill: none;
    stroke: #a15842;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-linejoin: round
}

#illustration-hero .st13 {
    fill: #ff7235
}

#illustration-hero .st14 {
    fill: none;
    stroke: #2f335b;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-miterlimit: 10
}

#illustration-hero .st15 {
    fill: #7394dd
}

#illustration-hero .st16,
#illustration-hero .st18 {
    fill: none;
    stroke-width: 4
}

#illustration-hero .st16 {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #30242a
}

#illustration-hero .st18 {
    stroke: #a15842;
    stroke-miterlimit: 10
}

#illustration-hero .st19 {
    fill: #3ec1f3
}

#illustration-hero .st20 {
    fill: #4756df
}

#illustration-hero .st22 {
    fill: none;
    stroke: #4756df;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-miterlimit: 10
}

#illustration-hero .st24 {
    fill: #eaa58b
}

#illustration-hero .st28 {
    fill: none;
    stroke: #30242a;
    stroke-width: 2;
    stroke-miterlimit: 10
}

#illustration-services .st0 {
    fill: #ececec
}
/* dark #3E3636 */
/* light #f5f9fa */
#blob {
    fill: #f5f9fa; 
}
#circle-interaction{
    fill: #df5646;
}